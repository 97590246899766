@import "mixins";

.article:not(.lg) .title {
  font-size: 12px;
}

.title {
  color: $white-44;
  font-weight: 500;

  @include mobile {
    margin-bottom: 4px;
  }

  @include desktop {
    margin-bottom: 8px;
  }

  text-transform: uppercase;
}

.content {
  font-weight: 500;

  @include mobile {
    font-size: 18px;
  }

  @include desktop {
    font-size: 28px;
  }
}

.lg .content {
  @include mobile {
    font-size: 32px;
  }

  @include desktop {
    font-size: 40px;
  }
}

.sm .content {
  @include mobile {
    font-size: 18px;
  }

  @include desktop {
    font-size: 20px;
  }
}

.xs .content {
  @include mobile {
    font-size: 14px;
  }

  @include desktop {
    font-size: 14px;
  }
}
