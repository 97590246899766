@import "mixins";

.send {
  @include flex;
  color: $blue;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.2px;

  svg {
    fill: $blue;
    margin-right: 4px;
  }
}

.rewards {
  @include mobile {
    margin-bottom: 40px;
  }

  @include desktop {
    margin-bottom: 36px;
  }
}

.astro,
.muted {
  margin-top: 4px;

  @include mobile {
    font-size: 12px;
  }

  @include desktop {
    font-size: 14px;
  }
}

.footer {
  text-align: center;
}
