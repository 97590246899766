@import "mixins";

.logo {
  display: block;
  padding: 16px 26px;
  border-bottom: solid 1px $gray-11;
}

.connect {
  padding: 0 12px;
}

.toggle {
  @include desktop {
    display: none;
  }
}
