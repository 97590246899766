@import "mixins";

.link {
  color: $white-52;
  font-size: 12px;
  font-weight: 500;
}

.chevron {
  fill: $blue;
  margin-left: 2px;
}

.check {
  fill: $gray-34;
}

.checked {
  fill: $blue;
}
