@import "mixins";

.grid {
  > :first-child {
    flex: 3;
  }

  > :last-child {
    flex: 2;
  }
}
