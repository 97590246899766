@import "mixins";

$width: 48px;
$height: 24px;
$gutter: 2px;
$inner: $height - 2 * $gutter;

.track {
  @include transition(background-color);

  background-color: $gray-34;
  border-radius: 12px;
  width: $width;
  height: $height;

  position: relative;
}

.on {
  background-color: $blue;
}

.thumb {
  @include transition(left);

  background-color: $white-80;
  border-radius: 50%;
  width: $inner;
  height: $inner;

  position: absolute;
  top: $gutter;
  left: $gutter;
}

.on .thumb {
  left: ($width - $inner - $gutter);
}
