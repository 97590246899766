@use "sass:math";
@import "mixins";

$item-gutter: 20px;

.header {
  @include flex;
  margin: 0 math.div(-$item-gutter, 2);

  @include mobile {
    justify-content: space-between;
  }

  @include desktop {
    justify-content: flex-end;
  }
}

.item {
  @include flex;

  @include mobile {
    height: $header-height-mobile;
    padding: 0 math.div($item-gutter, 2);
  }

  @include desktop {
    height: $header-height-desktop;
    padding: 0 math.div($item-gutter, 2);
  }
}

.network {
  flex: 1;
  justify-content: flex-end;
}
