@import "mixins";

.votes {
  @include flex(flex-end);
}

.label {
  text-transform: uppercase;
}

.label {
  @include flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;
  margin-bottom: 30px;

  small {
    font-size: 12px;
    margin-top: 5px;
  }

  &:not(:first-child) {
    border-left: $border;
  }
}

.answer {
  margin-bottom: 2px;
}

.danger {
  color: $red;

  svg {
    fill: $red;
  }
}
