@import "mixins";

.component {
  @include flex;

  position: fixed;
  top: 0;
  bottom: 80px;
  left: 0;
  right: 0;
}
