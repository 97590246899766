@import "mixins";
@import "variables";

.component {
  user-select: none;
  width: 100%;
}

.compact {
  display: inline-block;
  width: 80px;
}

/* axis */
$axis-height: 25px;
.axis {
  position: relative;
  height: $axis-height;
}

.x {
  position: absolute;
  top: 0;
  color: $white-52;
  font-size: 12px;
  font-weight: 500;

  @include mobile {
    font-size: 10px;
  }

  .compact & {
    color: $white-64;
    font-weight: normal;
    letter-spacing: -0.2px;
  }

  height: $axis-height;
  transform: translate(-50%, 0);
  white-space: nowrap;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 8px;
    background: $white-52;

    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
  }

  &.left {
    transform: translate(-100%, 0);

    &:after {
      left: 100%;
    }
  }

  &.right {
    transform: translate(0, 0);

    &:after {
      left: 0;
    }
  }
}

/* track */
.track {
  @include flex(flex-start, stretch);
  background: $gray-34;
  height: 8px;
  position: relative;
}

.thumb {
  @include flex;

  background-color: white;
  border-radius: 50%;
  border: 1px solid #101010;
  box-shadow: 0 0 3px 0 rgba(black, 0.4);
  cursor: grab;
  transform: translate(-50%, -50%);

  position: absolute;
  top: 50%;

  width: 24px;
  height: 24px;
}

/* dnd */
.cursor {
  cursor: crosshair;
  touch-action: none;
}

.dragging {
  cursor: grabbing;
}

.component:not(.dragging) {
  .item,
  .ratio {
    @include transition;
  }
}

/* feedback */
.feedback {
  margin-top: 5px;
  height: 20px;
}

.label {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

/* feedback:ratio */
.ratio {
  transform: translate(-50%, 0);
}

/* feedback:vote */
.vote {
  @include flex(space-between);
}

.text {
  text-align: center;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}
