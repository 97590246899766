@import "mixins";
@import "variables";

.wrapper {
  @include flex(flex-start, flex-start);
  flex-direction: column;
}

.button {
  margin-top: 20px;
  align-self: center;
}

.modal {
  padding: 0px 20px 20px 20px;
}

.message {
  background-color: $gray-11;
  border-radius: $border-radius;
  margin: 20px 0px;
  padding: 10px;
  overflow-x: auto;
}
