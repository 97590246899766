@import "mixins";

.message {
  @include flex(flex-start);
  display: inline-flex;
  white-space: pre;

  i {
    margin-left: 3px;
  }
}

.link {
  &,
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}

.ratio {
  margin-top: 32px;
}

.group {
  @include mobile {
    > :last-child {
      margin-top: $card-padding-vertical-mobile;
    }
  }

  @include desktop {
    @include flex;

    > :first-child {
      flex: 1;
    }

    > :last-child {
      flex: none;
      width: 120px;
      margin-left: 20px;
    }
  }
}
