@import "mixins";

$padding-caption-mobile: 16px $card-padding-horizontal-mobile;
$padding-th-mobile: 16px 16px;
$padding-td-mobile: 16px 16px;

$padding-caption-desktop: 20px $card-padding-horizontal-desktop;
$padding-th-desktop: 20px 16px;
$padding-td-desktop: 24px 16px;

.wrapper {
  width: 100%;
  overflow-x: scroll;
}

.radius {
  border-radius: 16px;
}

.margin {
  margin-top: 20px;
}

.cell {
  background-color: $gray-18;
  color: $white-80;
  white-space: nowrap;
}

.cell,
.caption {
  @include mobile {
    &:first-child {
      padding-left: $card-padding-horizontal-mobile;
    }

    &:not(:first-child) {
      padding-right: $card-padding-horizontal-mobile;
    }
  }

  @include desktop {
    &:first-child {
      padding-left: $card-padding-horizontal-desktop;
    }

    &:last-child {
      padding-right: $card-padding-horizontal-desktop;
    }
  }
}

.darker,
.darker .cell {
  background-color: $gray-14;
}

.clickable,
.clickable .cell {
  @include transition(background-color);
}

.clickable:hover,
.clickable:hover .cell {
  cursor: pointer;
  background-color: $gray-22;
}

.caption {
  background: $gray-14;
  color: $blue;
  font-size: 14px;
  font-weight: 500;

  @include mobile {
    padding: $padding-caption-mobile;
  }

  @include desktop {
    padding: $padding-caption-desktop;
  }

  &.border {
    border-bottom: 1px solid $bg;
  }
}

.th {
  background-color: $gray-14;
  color: $white-64;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;

  @include mobile {
    padding: $padding-th-mobile;
  }

  @include desktop {
    padding: $padding-th-desktop;
  }

  .colspan & {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    text-align: center;
  }
}

.td {
  letter-spacing: -0.3px;

  @include mobile {
    border-top: 1px solid $bg;
    padding: $padding-td-mobile;
  }

  @include desktop {
    border-top: 2px solid $bg;
    padding: $padding-td-desktop;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fixed-left {
  @include desktop {
    position: sticky;
    left: 0;
  }
}

.fixed-right {
  @include desktop {
    position: sticky;
    right: 0;
  }
}

.narrow-left {
  padding-left: 5px;
}

.narrow-right {
  padding-right: 5px;
}

/* color */
.red {
  background-color: fade-out($red, 0.92);
}

.bold {
  color: white;
  font-weight: 500;
}

.sub {
  font-size: 12px;
  letter-spacing: -0.3px;
  color: $white-44;
  margin-top: 2px;
}

.desktop {
  @include mobile {
    display: none;
  }
}

/* border */
.border-left {
  border-left: $border;
}

.border-right {
  border-right: $border;
}
