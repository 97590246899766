@import "mixins";

.grid {
  @include flex(space-between, flex-end);

  @include mobile {
    :first-child {
      order: 1;
    }
  }
}

.wrapper {
  > * + * {
    @include mobile {
      margin-top: 20px;
    }

    @include desktop {
      margin-left: 24px;
    }
  }

  @include desktop {
    @include flex;
  }
}
