@import "variables";

.headings {
  margin-bottom: 40px;
}

.title {
  color: $white-92;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.desc {
  color: $white-52;
  font-size: 14px;
  letter-spacing: -0.2px;
}
