@import "mixins";

.container {
  margin: 0 auto;

  @include mobile {
    padding: 0 $container-gutter-horizontal-mobile;
    max-width: ($max-width + 2 * $container-gutter-horizontal-mobile);
  }

  @include desktop {
    padding: 0 $container-gutter-horizontal-desktop;
    max-width: ($max-width + 2 * $container-gutter-horizontal-desktop);
  }
}

.sm {
  max-width: 650px;
  margin: 0 auto;
}
