@import "mixins";
@import "variables";

.component {
  @include flex(space-between);

  color: $blue;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.2px;

  &:hover {
    text-decoration: none;
  }

  svg {
    fill: $blue;
    @include mobile {
      margin-left: 4px;
    }
  }
}

@include desktop {
  .component {
    @include transition(background-color);

    background-color: $gray-14;
    border: solid 1px $blue;
    border-radius: 17px;
    height: 32px;
    padding: 0 14px;
    width: 170px;

    &:hover {
      background-color: fade-out($blue, 0.9);
    }
  }

  .connected {
    border: solid 1px $gray-24;
  }
}

.wrapper {
  @include flex;

  svg {
    @include mobile {
      margin-right: 4px;
    }

    @include desktop {
      margin-right: 8px;
    }
  }
}

.address {
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}
