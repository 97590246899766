@import "mixins";

$default: $white-64;
$active: $blue;

.menu {
  @include mobile {
    @include flex;
  }

  @include desktop {
    margin-top: 40px;
  }
}

.item {
  flex: 1;
}

.link {
  display: block;
  width: 100%;
  color: $default;

  .icon {
    fill: $default;
  }

  &.active {
    color: $active;

    .icon {
      fill: $active;
    }
  }

  @include desktop {
    font-size: 14px;
    padding: 2px 12px;
  }

  @include mobile {
    @include flex;
    font-size: 9.5px;
    font-weight: 500;
    height: $navigate-height-mobile;
    text-transform: uppercase;
  }
}

.wrapper {
  @include flex;

  border-radius: 8px;

  @include desktop {
    justify-content: flex-start;
    padding: 8px 12px;
  }

  @include mobile {
    flex-direction: column;
  }
}

.icon {
  @include desktop {
    margin-right: 12px;
    width: 22px;
    height: 22px;
  }

  @include mobile {
    width: 20px;
    height: 20px;

    margin-bottom: 4px;
  }
}

@include desktop {
  .link:hover .wrapper {
    background: $gray-18;
  }
}
