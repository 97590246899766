@import "mixins";
@import "variables";

.group + .group {
  @include mobile {
    margin-top: 32px;
  }

  @include desktop {
    margin-top: 32px;
  }
}

.component {
  input,
  textarea {
    width: 100%;
  }

  ::placeholder {
    color: fade-out(#f0f0f0, 0.8);
  }
}

$padding: 18px 24px;

.border {
  @include transition(border-color);
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: $gray-34;
  padding: $padding;
  position: relative;

  &.readOnly {
    $bg: $gray-22;
    border-color: $bg;
    background: $bg;
  }

  &.focused {
    border-color: $blue;
  }

  &.warn {
    border-color: $orange;
  }

  &.error {
    border-color: $red;
  }
}

/* field */
.header,
.wrapper {
  .type-1 &,
  .type-2 & {
    @include flex(space-between);

    .label,
    .field {
      flex: 1;
    }
  }

  .help,
  .unit {
    flex: none;
    text-align: right;
    @include flex(flex-end);
  }
}

.grid {
  @include flex;

  .border {
    flex: 1;
  }

  &,
  input {
    text-align: right;
  }
}

.placeholder {
  color: transparent;
}

.arrow {
  flex: none;
  fill: $blue;
  transform: rotate(-90deg);
  margin: 16px;
}

.header {
  color: $white-44;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
}

.type-3 .header {
  margin-bottom: 12px;

  @include desktop {
    @include flex(flex-start);
  }
}

.type-3 .meta {
  @include flex(space-between);
  @include desktop {
    flex: 1;
    border-left: 1px solid $gray-34;
    margin-left: 12px;
    padding-left: 12px;
  }

  .unit {
    color: $white-64;
    font-size: 14px;
  }
}

.type-1 .wrapper,
.type-2 .wrapper {
  @include mobile {
    font-size: 16px;
  }

  @include desktop {
    font-size: 18px;
  }
}

.label > label {
  display: block;

  .type-1 &,
  .type-2 & {
    text-transform: uppercase;
  }

  .type-3 & {
    font-size: 14px;
    color: $white-92;

    @include mobile {
      margin-bottom: 12px;
    }
  }
}

.help {
  white-space: pre;

  svg {
    fill: $blue;
    margin-right: 6px;
  }

  .hidden {
    fill: transparent;
  }
}

.clickable {
  color: $blue;
  cursor: pointer;
}

.max {
  margin-right: 10px;
}

.icon {
  margin-right: 12px;
}

.field,
.field input {
  text-align: right;
}

.left,
.left input {
  text-align: left;
}

.unit:last-child {
  margin-left: 4px;
}

/* assets */
.assets {
  margin-top: 10px;
}

/* feedback */
.feedback {
  color: $red;
  font-size: 12px;
  margin-top: 8px;
  text-align: right;

  &.warn {
    color: $orange;

    svg {
      fill: $orange;
    }
  }

  &.info {
    color: $white-80;

    svg {
      fill: $white-80;
    }
  }
}
