@import "mixins";

.component {
  max-width: $modal-width;
  margin: 0 auto;
}

.title {
  color: $white-92;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 32px;
}

.button {
  @include flex(space-between);
  background-color: $bg;
  border-radius: 8px;
  color: $blue;
  font-weight: 500;
  padding: 32px 28px;
  width: 100%;

  & + & {
    margin-top: 20px;
  }
}
