@import "mixins";

.dropdown {
  background-color: $gray-22;
  border-radius: $border-radius;
  border: $border;
  box-shadow: 0 10px 40px 0 fade-out(black, 0.7);
}

.list {
  padding: 10px 0;
}

.item a,
.item span {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  text-align: left;
  text-transform: capitalize;
  width: 160px;
}

.item a {
  @include link;

  &:hover {
    text-decoration: none;
  }
}

.item span {
  opacity: 0.25;
}
