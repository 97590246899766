@import "mixins";

.button {
  @include flex;
  @include link(white);

  svg {
    fill: $blue;
    margin-left: 8px;
  }
}

.icon {
  margin-right: 12px;
}
