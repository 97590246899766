@import "mixins";

.article {
  @include mobile {
    padding: 25px 0;
  }

  @include desktop {
    padding: 64px 0;
  }
}

.header {
  margin-bottom: 24px;

  @include desktop {
    @include flex(space-between);
  }
}

.heading {
  @include flex(space-between);

  @include desktop {
    justify-content: flex-start;
  }
}

.action {
  @include mobile {
    margin-top: 12px;
  }
}

.title {
  color: $white-92;
  font-weight: 500;
  letter-spacing: -0.3px;

  @include mobile {
    font-size: 24px;
  }

  @include desktop {
    font-size: 40px;
  }
}

.description {
  border-left: 1px solid $gray-18;
  color: $white-44;
  font-size: 60%;
  font-weight: normal;
  margin-left: 16px;
  padding-left: 16px;
}

.select {
  @include desktop {
    border: solid 1px fade-out($gray-34, 0.5);
    border-radius: 6px;
  }

  color: $white-64;
  margin-left: 12px;
  letter-spacing: -0.3px;
  padding: 10px 12px;
}

.icon {
  margin-right: 4px;
}
