*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: $font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  background: $bg;
  color: $text;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;

  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;

  border: 0;
  border-bottom: 1px solid $hr;

  margin-top: 16px;
  margin-bottom: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 500;
  margin: 0;
}

p,
ul,
ol,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

strong,
.strong {
  font-weight: 500;
}

small,
.small {
  font-size: 75%;
}

a {
  color: inherit;
  text-decoration: none;
}

[target="_blank"] {
  @include link($blue, $blue);
  &:hover {
    text-decoration: underline;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

caption,
th {
  text-align: left;
}

button,
input,
select,
textarea {
  border: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  color: inherit;
  font: inherit;
}

button,
select {
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: unset;
  }
}

input:disabled,
textarea:disabled,
select:disabled {
  opacity: 0.5;
}

textarea {
  resize: none;
}

svg {
  fill: white;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  display: none;
}
