@import "mixins";
@import "variables";

.wrapper {
  margin-bottom: 20px;

  :only-child {
    flex: 1;
  }

  @include desktop {
    @include flex(space-between);
  }
}

.meta {
  @include flex(space-between);
  color: $gray-34;
  font-weight: 500;
}

.id,
.type {
  @include desktop {
    font-size: 14px;
  }
  @include mobile {
    font-size: 12px;
  }
}

.type {
  @include flex();
  flex-direction: column;
  text-transform: capitalize;
}

.wrapper > :first-child:not(:only-child) {
  margin: 0 -20px;

  .id,
  .type {
    padding: 0 20px;
  }

  .type {
    border-left: 1px solid $hr;
  }
}

.status {
  @include flex(flex-start);

  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 8px;
  text-transform: uppercase;

  @include mobile {
    font-size: 12px;
  }

  svg {
    margin-right: 5px;
  }
}

.blue {
  color: $blue;

  svg {
    fill: $blue;
  }
}

.red {
  color: $red;

  svg {
    fill: $red;
  }
}

.strike {
  text-decoration: line-through;
}

.title {
  color: white;
  font-weight: normal;
}
