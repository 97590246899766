@import "mixins";
@import "variables";

.component {
  @include flex(space-between);
  flex-direction: column;
  height: 100%;

  .main,
  .footer,
  .note {
    width: 100%;
  }

  .main {
    flex: 1;
  }

  .footer,
  .note {
    flex: none;
  }
}

.title {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  @include mobile {
    font-size: 16px;
  }
}

/* footer */
.footer {
  @include flex(space-between);
  color: $gray-34;
  font-size: 12px;
  margin-top: 30px;
  white-space: pre;

  i {
    margin-left: 5px;
    margin-right: 3px;
  }
}

.note {
  color: $orange;
  font-size: 12px;
  margin-top: 4px;
}
