@import "mixins";

.header {
  @include flex(flex-start);
  margin-bottom: 4px;
}

.label {
  color: $white-44;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}

.square {
  width: 7px;
  height: 7px;
  margin-right: 6px;
}

.value {
  margin-left: (7px + 6px);

  color: $white-92;
  font-size: 14px;
}
