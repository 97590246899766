@import "mixins";
@import "variables";

.success {
  fill: $blue;
}

.failure {
  fill: $red;
}

.feedback {
  background: $gray-14;
  border-radius: 4px;
  color: $white-92;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  padding: 20px;
  text-align: center;
  word-break: break-word;
}
