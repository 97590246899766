@import "mixins";

.header {
  @include flex(space-between);

  @include mobile {
    margin-top: 40px;
    margin-bottom: 12px;
  }

  @include desktop {
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.title {
  color: white;
}

.wrapper {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    pointer-events: none;
  }
}

.checkbox {
  font-size: 12px;
  margin-right: 16px;
}

.select {
  appearance: none;
  border: 1px solid $gray-34;
  border-radius: 3px;
  font-size: 12px;
  padding: 6px 10px;
  padding-right: (6px + 16px + 6px);
  text-transform: capitalize;
}

.dim {
  opacity: 0.5;
}
