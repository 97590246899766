@import "mixins";
@import "variables";

.modal {
  width: $modal-width;
  outline: 0;
  max-height: 100vh;
  overflow-y: auto;
}

.overlay {
  @include flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.75);
  z-index: $zindex-modal;
}
