@import "mixins";
@import "variables";

.list {
  border-radius: $border-radius;

  @include mobile {
    font-size: 12px;
  }

  @include desktop {
    font-size: 14px;
  }

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.item:not(:first-child) {
  margin-top: 16px;
}

.article {
  @include flex(space-between);
}

.title {
  color: $white-44;
  font-weight: normal;
}

.content {
  color: $white-80;
}
