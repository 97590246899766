@import "mixins";
@import "variables";

$padding: 8px;

.community {
  @include flex(flex-start);
  margin: -$padding;
}

.link {
  @include flex;
  @include transition(opacity);
  padding: $padding;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}
