@import "mixins";

.list {
  margin-bottom: -20px;
}

.item {
  @include flex(space-between);
  margin-bottom: 20px;
}
