@import "mixins";

$padding: 20px;

.component {
  border-radius: $border-radius;
}

.details {
  @include flex(space-between, flex-start);
  flex-wrap: wrap;

  font-size: 12px;
  margin-top: 40px;

  dt,
  dd {
    width: 50%;
    margin-bottom: 12px;
  }

  dt {
    color: $white-44;
    font-weight: 500;
    letter-spacing: -0.2px;
  }

  dd {
    color: $white-64;
    text-align: right;
  }

  dd p {
    margin-bottom: 2px;
  }
}

.ranges {
  @include flex;

  background: $gray-11;
  padding: 1px 0;
}

.button {
  @include transition;

  flex: 1;

  background: $gray-18;
  color: $white-44;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.26px;
  height: 40px;
  width: 100%;

  &.active {
    color: $blue;
  }

  & + & {
    margin-left: 1px;
  }
}

.footer {
  padding: $padding;
}
