@import "mixins";
@import "variables";

.wrapper {
  @include flex;
  margin: 20px 0;

  svg {
    fill: $blue;
  }
}
