@import "mixins";

.component {
  @include flex;
}

.compact {
  display: inline-flex;
}

.percent {
  margin-right: 12px;
}
