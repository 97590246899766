@use "sass:math";
@import "mixins";

$height: 15px;

.badge {
  @include flex;

  background: fade-out(white, 0.9);
  border-radius: 4px;
  color: $gray-08;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  height: $height;
  padding: 0 4px;
  text-transform: uppercase;
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color;
  }
}
