@import "mixins";

.card {
  background: $card;

  border-radius: $border-radius;
  position: relative;
  overflow: hidden;

  &.lg {
    border-radius: 8px;
  }
}

.flex {
  @include flex(space-between, stretch);
  flex-direction: column;
}

.shadow {
  box-shadow: 0 0 40px 0 fade-out(black, 0.7);
}

.link {
  @include transition(border-color);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  &:hover {
    border-color: $blue;
  }
}

/* main */
.main {
  @include mobile {
    padding: $card-padding-main-mobile;
  }

  @include desktop {
    padding: $card-padding-main-desktop;
  }

  &:first-child {
    @include mobile {
      padding-top: $card-padding-vertical-mobile;
    }

    @include desktop {
      padding-top: $card-padding-vertical-desktop;
    }
  }
}

/* confirm */
.confirm {
  background-color: $gray-14;
  border-top: 1px solid $bg;
}
