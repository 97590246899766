@use "sass:math";
@import "mixins";

$margin-horizontal-mobile: math.div($gutter-horizontal-mobile, 2);
$margin-vertical-mobile: math.div($gutter-vertical-mobile, 2);
$margin-horizontal-desktop: math.div($gutter-horizontal-desktop, 2);
$margin-vertical-desktop: math.div($gutter-vertical-desktop, 2);

$margin-mobile: $margin-vertical-mobile $margin-horizontal-mobile;
$margin-desktop: $margin-vertical-desktop $margin-horizontal-desktop;
$negative-margin-mobile: (-$margin-vertical-mobile) (-$margin-horizontal-mobile);
$negative-margin-desktop: (-$margin-vertical-desktop)
  (-$margin-horizontal-desktop);

.row {
  @include flex(flex-start);
  align-items: stretch;

  > * {
    flex: 1;

    @include mobile {
      margin: $margin-mobile;
    }

    @include desktop {
      margin: $margin-desktop;
    }
  }

  @include mobile {
    margin: $negative-margin-mobile;
    flex-direction: column;
  }

  @include desktop {
    margin: $negative-margin-desktop;
  }

  & + & {
    @include mobile {
      margin-top: $margin-vertical-mobile;
    }

    @include desktop {
      margin-top: $margin-vertical-desktop;
    }
  }
}

.gutter {
  @include mobile {
    margin-top: $gutter-vertical-mobile;
  }

  @include desktop {
    margin-top: $gutter-vertical-desktop;
  }
}

@include desktop {
  .wrap {
    flex-wrap: wrap;

    > * {
      flex: none;
    }
  }

  .wrap-2 > * {
    @include mobile {
      width: calc((100% / 2) - #{$gutter-horizontal-mobile});
    }

    @include desktop {
      width: calc((100% / 2) - #{$gutter-horizontal-desktop});
    }
  }
}
