@import "mixins";

.component {
  @include flex(flex-start, flex-start);
  border-radius: 4px;
  color: $white-80;
  font-size: 14px;
  margin-top: 32px;
  padding: 16px 20px;
  letter-spacing: -0.2px;
  line-height: 1.5;

  svg {
    flex: none;
    margin-right: 8px;
    margin-top: 2px;
  }
}

.full {
  padding: 0;
}

.help {
  padding: 0;
  color: $white-64;

  > svg {
    fill: $white-44;
  }
}

.warn {
  background: rgba($orange, 0.15);

  > svg {
    fill: $orange;
  }
}

.error {
  background: rgba($red, 0.15);

  > svg {
    fill: $red;
  }
}
