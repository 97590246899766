@import "mixins";
@import "variables";

$radius: 10px;

.wrapper {
  background: $gray-14;
  border-radius: $border-radius;
  overflow-x: auto;

  @include mobile {
    margin-bottom: $gutter-vertical-mobile;
  }

  @include desktop {
    margin-bottom: $gutter-vertical-desktop;
  }
}

.tabs {
  @include flex(flex-start);
}

.tab {
  @include flex;
  @include link;

  color: $gray-34;
  font-size: 14px;
  letter-spacing: -0.2px;

  white-space: nowrap;
  width: 112px;

  @include mobile {
    height: 52px;
  }

  @include desktop {
    height: 60px;
  }

  &.active {
    background: $gray-18;
    color: $white-80;
  }

  @include mobile {
    flex: 1;
    padding: 0 16px;

    &:not(:first-child) {
      border-left: 1px solid $bg;
    }
  }

  @include desktop {
    border-right: 1px solid $bg;
  }
}
