@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Book_Web.woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Medium_Web.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Bold_Web.woff2");
  font-weight: bold;
}
