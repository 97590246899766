@import "variables";

.component {
  border: 1px solid $blue;
  border-radius: 8px;
  color: $blue;
  font-size: 14px;
  line-height: 1.5;
  padding: 20px;
  margin-bottom: 40px;
}
