@import "mixins";

.contents {
  margin-top: 40px;
}

.p {
  color: $white-92;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 24px;

  strong {
    color: $blue;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.info {
  background-color: $gray-14;
  border-radius: 5px;
  color: white;
  margin-bottom: 24px;
  padding: 16px 32px;

  li + li {
    margin-top: 12px;
  }
}

.asset {
  @include flex;
  font-weight: 500;

  .icon {
    margin-right: 8px;
  }

  .date {
    color: $white-80;
    font-weight: normal;
    margin-left: 16px;
  }
}

.list {
  li {
    @include flex(flex-start, flex-start);
    color: $white-64;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 24px;

    position: relative;
    padding-left: 16px;
  }

  li:before {
    flex: none;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    content: "";
    display: block;

    width: 1px;

    background-color: $gray-34;
  }

  strong {
    font-weight: bold;
  }
}

.italic {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 24px;
}

.link {
  @include link($blue, $blue);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.checkbox {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
