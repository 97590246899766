@import "mixins";

.header {
  color: $blue;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.2px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.button {
  @include flex(flex-start);
  @include link($white-92, $white-92);

  padding: 15px 0;
  text-align: left;

  &:hover {
    text-decoration: none;
  }

  svg {
    fill: $white-92;
  }
}

.name {
  flex: 1;
  margin-left: 8px;
}
